<template>
  <transition name="slide">
    <div class="member-container">
      <div class="top">
        <h1 class="h">
          <i class="cubeic-back back" @click="back" />
        </h1>
        <div class="user">
          <div class="avatar">
            <img :src="user.avatar" />
          </div>
          <div class="text">
            <span class="name" v-text="user.nickName" />
          </div>
          <div class="level-bar">
            <div class="l">
              <icon-level :level="user.lv" />
            </div>
            <div class="m">
              <div class="bar">
                <div
                  class="inner"
                  :style="`width: ${user.nextLvExpProcess}%`"
                />
                <div class="text" v-text="`${user.nextLvExpProcess}%`" />
              </div>
            </div>
            <div class="r">
              <icon-level :level="user.lv + 1" />
            </div>
          </div>
          <div class="next-level-text">
            还需要{{ user.nextLvExp }}经验值达到LV{{ user.lv + 1 }}
          </div>
        </div>
      </div>
      <div class="main">
        <cube-scroll ref="scroll" :options="options">
          <div class="title">
            <div class="l" />
            <div class="m">
              等级特权
            </div>
            <div class="r" />
          </div>
          <div class="level-power">
            <dl v-for="(item, inx) in top" :key="inx">
              <dt>
                <img :src="item.icon" />
              </dt>
              <dd class="name" v-text="item.name" />
              <dd class="slogan" v-text="item.slogan" />
              <dd class="state" v-text="item.state" />
            </dl>
          </div>
          <div class="title not-margin-t">
            <div class="l" />
            <div class="m">
              如何快速升级
            </div>
            <div class="r" />
          </div>
          <div class="how-up-level">
            <dl v-for="(item, inx) in how" :key="inx" class="border-bottom-1px">
              <dt>
                <img :src="item.icon" />
              </dt>
              <dd>
                <div class="name" v-text="item.name" />
                <div class="desc" v-text="item.desc" />
              </dd>
            </dl>
          </div>
        </cube-scroll>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState } from "vuex";
import IconLevel from "@/components/room/icon/level/level";
export default {
  components: {
    IconLevel
  },
  data() {
    return {
      top: [
        {
          name: "精选社区",
          icon: require("./img/top-1.png"),
          slogan: "德州爱好者聚集地",
          state: "敬请期待"
        },
        {
          name: "社区发帖",
          icon: require("./img/top-2.png"),
          slogan: "社区互动成为网红",
          state: "敬请期待"
        },
        {
          name: "弹幕颜色",
          icon: require("./img/top-3.png"),
          slogan: "专属弹幕自选颜色",
          state: "20级开启"
        },
        {
          name: "荣誉头衔",
          icon: require("./img/top-4.png"),
          slogan: "独特头衔彰显气质",
          state: "30级开启"
        },
        {
          name: "进场特效",
          icon: require("./img/top-5.png"),
          slogan: "进场特效亮瞎全场",
          state: "50级开启"
        },
        {
          name: "",
          icon: require("./img/top-6.png"),
          slogan: "",
          state: "敬请期待"
        }
      ],
      how: [
        {
          icon: require("./img/how-1.png"),
          name: "赠送礼物",
          desc: "礼物刷刷刷，经验biubiubiu"
        },
        {
          icon: require("./img/how-2.png"),
          name: "兑换金币",
          desc: "兑换一时爽，经验更加爽"
        },
        {
          icon: require("./img/how-3.png"),
          name: "VIP加速",
          desc: "经验领先一步，额外增加18%的经验"
        },
        {
          icon: require("./img/how-4.png"),
          name: "兑换VIP",
          desc: "会员显尊贵，经验不浪费"
        },
        {
          icon: require("./img/how-5.png"),
          name: "每天签到",
          desc: "每日斗牌一游，经验力争上游"
        },
        {
          icon: require("./img/how-6.png"),
          name: "在线时长",
          desc: "地球不爆炸，我们不放假"
        },
        {
          icon: require("./img/how-7.png"),
          name: "发送弹幕",
          desc: "互动多多，经验多多"
        },
        {
          icon: require("./img/how-8.png"),
          name: "分享",
          desc: "分享视频或者直播间，花样拿经验"
        }
      ],
      options: {
        click: true
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  methods: {
    back() {
      this.$router.back();
    },
    toLink(link) {
      this.$router.push(link);
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.member-container
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  overflow hidden
  z-index 10
  .top
    height 226px
    background linear-gradient(to bottom, #2364CF, #2189E2)
    overflow hidden
    .h
      margin 10px 13px
      font-size 17px
      color #fff
      .setting
        float right
        position relative
        top 3px
    .user
      .avatar
        text-align center
        img
          width 76px
          height 76px
          border 4px solid #fff
          border-radius 50%
      .text
        text-align center
        .name
          font-size 17px
          color #fff
      .level-bar
        display flex
        align-items center
        margin 13px 50px
        .m
          flex 0 0 165px
          height 8px
          margin 0 14px
          .bar
            position relative
            width 100%
            height 100%
            background rgba(255, 255, 255, .5)
            .inner
              height 100%
              background #2EB3E5
            .text
              position absolute
              top 50%
              left 50%
              transform translate3d(-50%, -50%, 0) scale(.8)
              font-size 10px
              color #fff
      .next-level-text
        margin-bottom 25px
        font-size 12px
        text-align center
        color #fff
  .main
    height calc(100vh - 226px)
    .title
      display flex
      align-items center
      margin 19px 0 24px
      &.not-margin-t
        margin-top 0
      .l,.r
        flex 1
        height 1px
        background #2DC6F1
      .m
        flex 0 0 135px
        height 30px
        line-height 30px
        border 1px solid #2DC6F1
        border-radius 7px
        text-align center
        font-size 17px
        font-weight bold
        color #1E88E4
    .level-power
      display flex
      flex-wrap wrap
      margin 0 11.25px
      dl
        flex 0 0 110px
        margin 0 11.25px 27px 0
        text-align center
        &:nth-of-type(3n+0)
          margin-right 0
        dt
          img
            width 50px
            height 50px
        .name
          margin-bottom 7px
          font-size 15px
          color #383A40
        .slogan
          margin-bottom 7px
          font-size 12px
          color #6B6B6B
        .state
          width 82px
          height 25px
          line-height 25px
          margin 0 auto
          border 1px solid #EAE9E9
          font-size 13px
          color #6B6B6B
          text-align center
    .how-up-level
      dl
        display flex
        align-items center
        margin 9px 24px 9px 4px
        dt
          flex 0 0 86px
          padding-left 17px
          img
            width 50px
            height 50px
        dd
          .name
            font-size 15px
            color #383A40
          .desc
            font-size 12px
            color #6B6B6B
</style>
