<template>
  <!-- <div class="grade-wrap" :class="`grade-${level < 1 ? 1 : level}`" /> -->
  <span class="level" :class="[getLv()]">
    <i />
    <b>{{ level }}</b>
  </span>
</template>
<script>
export default {
  props: {
    level: {
      type: Number,
      default() {
        return 1;
      }
    }
  },
  methods: {
    getLv() {
      const findLv = this.level;
      if (findLv <= 10) return `lv1`;
      if (findLv <= 20) return `lv11`;
      if (findLv <= 30) return `lv21`;
      if (findLv <= 40) return `lv31`;
      if (findLv <= 50) return `lv41`;
      if (findLv <= 60) return `lv51`;
      if (findLv <= 70) return `lv61`;
      if (findLv > 70) return `lv71`;
    }
  }
};
</script>

<style lang="stylus">
.level
  display inline-block
  width 36px
  height 15px
  line-height 15px
  vertical-align middle
  margin-right 6px
  background #4E92F7
  border-radius 2px
  text-align center
  font-size 0
  &.lv11
    background-color #6881F0
    i
      background-image url(./img/lv2.png)
  &.lv21
    background-color #8F68F2
    i
      background-image url(./img/lv3.png)
  &.lv31
    background-color #C93EFC
    i
      background-image url(./img/lv4.png)
  &.lv41
    background-color #F33276
    i
      background-image url(./img/lv5.png)
  &.lv51
    background-color #F01F18
    i
      background-image url(./img/lv6.png)
  &.lv61
    background-color #F4B200
    i
      background-image url(./img/lv7.png)
  &.lv71
    background-color #FFE400
    i
      background-image url(./img/lv8.png)
  i
    display inline-block
    vertical-align middle
    width 11px
    height 11px
    background url(./img/lv1.png) no-repeat center center
    background-size 100%
    margin-right 2px
  b
    display inline-block
    vertical-align middle
    font-size 10px
    font-weight 400
    color #fff
</style>
